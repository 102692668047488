import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import VerifiedIcon from './VerifiedIcon';
import { tablet } from 'styles/breakpoints';
import { DynamicImage } from 'components/images/DynamicImage';

const ReviewCard: FC = () => (
  <CardContainer>
    <ImageContainer>
      <StyledImage
        src="mg/reviews/main-review.png"
        alt="Happy woman with a book"
      />
    </ImageContainer>
    <StyledText>
      &ldquo;Moongrade became a must-have for me for its daily insights on my
      relationships. It helps me avoid conflicts and shows me the best days to
      work with others.&rdquo;
    </StyledText>
    <UserRatingContainer>
      <div>
        <UserName>Rachel W., 24</UserName>
        <StarsContainer>
          <StarIcons starCount={5} />
        </StarsContainer>
      </div>
      <VerifiedBadge>
        <VerifiedIcon />
        <VerifiedText>Verified</VerifiedText>
      </VerifiedBadge>
    </UserRatingContainer>
  </CardContainer>
);

export default ReviewCard;

const CardContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 21.4375rem;
  padding: 1.5rem 1rem;
  border-radius: 0.625rem;
  margin-bottom: 1.25rem;
  border: 1px solid #f6f4ee;

  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
`;

const StyledImage = styled(DynamicImage)`
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
`;

const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  margin: 0;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e6e5e1;
`;

const UserRatingContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const VerifiedBadge = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
  background: #498d27;
  border-radius: 1rem;
`;

const VerifiedText = styled.span`
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
  color: #fff;
  font-family: 'Open Sans' !important;
`;
const UserName = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
`;
const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
