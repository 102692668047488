interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'mg/reviews/mg1.webp',
    title: 'My love life is much better',
    description: `“This app has totally changed how I approach my relationships. Knowing my compatibility with different signs helped me choose the right partner.”`,
    name: 'Mia L.',
  },
  {
    url: 'mg/reviews/mg2.webp',
    title: 'Incredibly helpful',
    description: `“Moongrade makes my daily interactions so much easier. Daily horoscopes helps me plan meetings and dates for better results.”`,
    name: 'Luna P.',
  },
  {
    url: 'mg/reviews/mg3.webp',
    title: 'Such an amazing app',
    description: `"As a cancer, I appreciate how the book recognized my creative nature. It inspired me to start a blog that’s now gaining traction."`,
    name: 'Charlotte B.',
  },
  {
    url: 'mg/reviews/mg4.webp',
    title: 'I feel truly heard',
    description: `"I felt stuck in life, both personally and financially. Moongrade provided real guidance that gave me clarity. Now I know what to expect."`,
    name: 'Leo P.',
  },
  {
    url: 'mg/reviews/mg5.webp',
    title: 'Feeling hopeful again',
    description: `“After a painful breakup, I was feeling miserable. Moongrade gave me hope that love is just around the corner. I feel worthy again.”`,
    name: 'Ava T.',
  },
];
