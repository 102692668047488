interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'katebay',
    comment:
      'I LOVE checking this app each morning! It helps me connect with the right people and avoid the wrong ones.',
  },
  {
    author: '94jane.brown',
    comment:
      'Love the daily insights! It\'s made my relationships WAY better—like, 200% better!',
  },
  {
    author: 'ssamanthaa',
    comment: `The compatibility feature is EVERYTHING! It helped me find my perfect man without having to go on hundreds of bad dates.`,
  },
  {
    author: 'laurel.lynch',
    comment:
      'This app has turned me into a zodiac expert! I never knew how much signs mattered until now!',
  },
  {
    author: '777rose',
    comment:
      'Now I see how compatibility works! Just a quick astrological check, and I know exactly who I vibe with!',
  },
  {
    author: 'all.you.need.is.love',
    comment:
      'My life has transformed since I read my birth chart analysis. I’m attracting the right people and ditching the drama.',
  },
];
