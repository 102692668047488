import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import { tablet } from 'styles/breakpoints';
//@ts-ignore
import MgReviewsStars from 'assets/icons/mg/MgReviewsStars.svg';

interface UserReviewProps {
  author: string;
  comment: string;
}

const UserReviewCard: FC<UserReviewProps> = ({ author, comment }) => (
  <ReviewContainer>
    <Rating>
      <strong>{author}</strong>
      <div>
        <MgReviewsStars />
      </div>
    </Rating>
    <p>{comment}</p>
  </ReviewContainer>
);

export default UserReviewCard;

const ReviewContainer = styled.div`
  display: flex;
  padding: 1rem;
  width: 100%;
  max-width: 21.4375rem;
  min-height: 11.125rem;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  background: #fff;

  @media ${tablet} {
    max-width: 17.5rem;
  }

  p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.176px;
  }
`;

const Rating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  strong {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }
`;
