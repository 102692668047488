import React, { FC } from 'react';
import styled from 'styled-components';
import { useRouter } from 'apis/history';

interface CtaButtonProps {
  buttonText: string;
}

const CtaButton: FC<CtaButtonProps> = ({ buttonText }) => {
  const { goToLanding } = useRouter();

  return (
    <ButtonContainer onClick={() => goToLanding()}>
      <StyledCTA>{buttonText}</StyledCTA>
    </ButtonContainer>
  );
};

export default CtaButton;

const ButtonContainer = styled.div`
  width: 100%;
`;

const StyledCTA = styled.button`
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  min-height: 3rem;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  background: #ffd128;
  font-family: 'Afacad', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
