interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl: 'mg/reviews/success1.png',
    story: `"I was confused about my relationship, but the compatibility test helped me understand it better. Once I saw why we were hitting bumps, we had some heart-to-hearts and leveled up. Now I’m engaged and more in love than ever!"`,
    author: 'Ella C., 28',
  },
  {
    imgUrl: 'mg/reviews/success2.png',
    story:
      '“I was kinda skeptical, but Moongrade hit different! I was in a relationship that just felt off, and the compatibility insights helped me see why. I took a step back and moved on, and now I’m happier and ready for a healthier love life!”',
    author: 'Lucy R., 30',
  },
  {
    imgUrl: 'mg/reviews/success3.png',
    story: `"I check the horoscopes every morning to see what my day looks like. It’s like having a cheat sheet for making friends and networking. My relationships are so much better now, both in my personal life and at work!"`,
    author: 'Laura A.,  31',
  },
  {
    imgUrl: 'mg/reviews/success4.png',
    story:
      '“As a big tarot fan, I use Moongrade for daily readings. And they really help me understand some recent challenges I’ve faced. I left my toxic bf because of that and cannot be happier. I recommend it for anyone seeking daily guidance."',
    author: 'Stella L., 26',
  },
  {
    imgUrl: 'mg/reviews/success5.png',
    story:
      '“I was sick of dating all the wrong people and getting hurt. Moongrade showed me who I actually vibe with. Knowing that I made better choices and finally met someone who matches my energy. It feels so good to be on the right track.”',
    author: 'Daniel R., 35',
  },
  {
    imgUrl: 'mg/reviews/success6.png',
    story:
      '"I had no clue how zodiac compatibility could change my family dynamics. I finally understood why my relatives act the way they do, and I learned how to handle tough conversations. Now, family gatherings are way more fun and relaxed."',
    author: 'Esther K., 32',
  },
];
