import React, { FC } from 'react';
import styled from 'styled-components';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { userReviews } from 'utils/userReviews';
import UserReviewCard from './UserReviewCard';
import CtaButton from './CtaButton';

const SuccessfulCustomersSection: FC = () => {
  const { isTablet } = useQuery();
  return (
    <StyledSection
      backgroundColor="#F6F6F6;"
      sectionPadding="56px 16px"
      marginTop="0px"
    >
      <SectionHeading marginBottom="4px">
        Over 2.9 million successful customers
      </SectionHeading>
      <SectionSubheading marginBottom={isTablet ? '24px' : '38px'}>
        Read why they swear by Moongrade
      </SectionSubheading>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 15 : 22}
        >
          {userReviews.map((review, index) => (
            <SwiperSlide key={index}>
              <UserReviewCard
                key={index}
                author={review.author}
                comment={review.comment}
              />
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
      <ButtonContainer>
        <CtaButton buttonText="Start your journey" />
      </ButtonContainer>
    </StyledSection>
  );
};

export default SuccessfulCustomersSection;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-slide {
    width: 100%;
    max-width: 343px;
    margin-bottom: 28px;
  }

  @media ${tablet} {
    .swiper-slide {
      width: 100%;
      max-width: 280px !important;

      @media ${tablet} {
        margin-bottom: 24px;
      }
    }
  }

  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: ${({ theme }) => theme.colors.white};
    &-active {
      background-color: #f7c800;
      width: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  max-width: 343px;
  margin-top: 16px;
  width: 100%;

  @media ${tablet} {
    margin-top: 8px;
  }
`;
