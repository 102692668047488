import { DynamicImage } from 'components/images/DynamicImage';
import React, { FC } from 'react';
import styled from 'styled-components';

interface VideoCardProps {
  video: string;
  title: string;
  description: string;
  reviewer: string;
}

const ReviewImage = styled(DynamicImage)``;

const VideoReviewsCard: FC<VideoCardProps> = ({
  video,
  title,
  description,
  reviewer,
}) => (
  <StyledCard>
    <ReviewImage src={video} alt={title} />
    <StyledReview>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Reviewer>{reviewer}</Reviewer>
    </StyledReview>
  </StyledCard>
);

export default VideoReviewsCard;

const StyledCard = styled.div`
  width: 258px;
  display: flex;
  flex-direction: column;

  img {
    object-fit: cover;
    object-position: top;
    border-radius: 0.625rem 0.625rem 0 0;
    /* margin-bottom: -3px; */
    height: 19.0625rem;
    width: 100%;
  }
`;

const StyledReview = styled.div`
  display: flex;
  width: 100%;
  max-width: 258px;
  min-height: 144px;
  padding: 1rem 0.75rem;
  flex-direction: column;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  background: #f6f6f6;
  margin-top: -4px;

  strong:first-of-type {
    margin-bottom: 6px;
  }

  strong {
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 8px;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 0.375rem;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

const Reviewer = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
`;
