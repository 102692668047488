import React, { FC } from 'react';

interface StarIconsProps {
  starCount: number;
}

const StarIcons: FC<StarIconsProps> = ({ starCount }) => (
  <>
    {Array.from({ length: starCount }).map((_, index) => (
      <svg
        key={index}
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame">
          <g id="Group">
            <path
              id="Vector"
              d="M9.33006 11.8352C9.06506 11.8352 8.72506 11.7502 8.30006 11.5002L6.80506 10.6152C6.65006 10.5252 6.35006 10.5252 6.20006 10.6152L4.70006 11.5002C3.81506 12.0252 3.29506 11.8152 3.06006 11.6452C2.83006 11.4752 2.47006 11.0402 2.70506 10.0402L3.06006 8.50516C3.10006 8.34516 3.02006 8.07016 2.90006 7.95016L1.66006 6.71016C1.04006 6.09016 1.09006 5.56016 1.17506 5.30016C1.26006 5.04016 1.53006 4.58016 2.39006 4.43516L3.98506 4.17016C4.13506 4.14516 4.35006 3.98516 4.41506 3.85016L5.30006 2.08516C5.70006 1.28016 6.22506 1.16016 6.50006 1.16016C6.77506 1.16016 7.30006 1.28016 7.70006 2.08516L8.58006 3.84516C8.65006 3.98016 8.86506 4.14016 9.01506 4.16516L10.6101 4.43016C11.4751 4.57516 11.7451 5.03516 11.8251 5.29516C11.9051 5.55516 11.9551 6.08516 11.3401 6.70516L10.1001 7.95016C9.98006 8.07016 9.90506 8.34016 9.94006 8.50516L10.2951 10.0402C10.5251 11.0402 10.1701 11.4752 9.94006 11.6452C9.81506 11.7352 9.61506 11.8352 9.33006 11.8352Z"
              fill="#FFD128"
            />
          </g>
        </g>
      </svg>
    ))}
  </>
);

export default StarIcons;
